import React from "react";

type InputLabelProps = {
  id: string;
  value: string;
};

export const InputLabel: React.FC<InputLabelProps> = ({ id, value }) => {
  return (
    <label htmlFor={`form-${id}`} className="font-weight-bold">
      {value}
    </label>
  );
};
