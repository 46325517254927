import React, {PropsWithChildren, ReactElement} from "react";
import "./titled-section.scss";

type SectionProps = {
  title?: string;
  button?: ReactElement;
};

export const TitledSection = ({title, button, children}: PropsWithChildren<SectionProps>) => {
  return (
    <div className="container-fluid my-5 section">
      {(title || button) && (
        <div className="row mb-3">
          {title && (
            <div className={`col text-uppercase section_title`}>{title}</div>
          )}
          {button && (
            <div className="col-4 align-self-end text-right">{button}</div>
          )}
        </div>
      )}
      {children}
    </div>
  );
};
