import React from "react";
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import "./menu.scss";

type MenuType = {
  homeUrl?: string
  items?: MenuItemType[];
  Link: any
}

type MenuItemType = {
  name?: string;
  type?: string;
  dropdown?: MenuItemType[];
  link?: string;
  isExternal?: boolean;
}


export const Menu: React.FC<MenuType> = ({Link, homeUrl = '/', items = []}) => {
  const renderMenu = (item: MenuItemType) => {
    switch (item.type) {
      case 'dropdown':
        return <NavDropdown title={item.name} id="basic-nav-dropdown" className="menu_dropdown">
          {(item.dropdown || []).map(renderMenu)}
        </NavDropdown>;
      case 'divider':
        return <NavDropdown.Divider className="menu_dropdown_divider"/>;
      default:
        if (item.isExternal)
          return <Nav.Link href={item.link} target={"_blank"}><a
            className="menu_dropdown_link">{item.name}</a></Nav.Link>
        return <Nav.Link><Link to={item.link} className="menu_dropdown_link">{item.name}</Link></Nav.Link>
    }
  }

  return (
    <Navbar bg="light" expand="lg" className="menu text-uppercase">
      <div style={{marginTop: 10, marginBottom: -40}} className="container">
        <Navbar.Brand>
          <Link to={homeUrl} className="menu_dropdown_link">
            <div className="menu_logo"/>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav" className="menu_collapse">
          <Nav className="mr-auto"/>
          <Nav style={{padding: 10}}>
            {items.map(renderMenu)}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}
