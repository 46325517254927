import React from 'react';
import './error-boundary.scss';
import {clean as cleanLocalStorage} from "../../tools/localStorage";


export class ErrorBoundary extends React.Component {
  static disabled = false;


  constructor(props) {
    super(props);
    this.state = {error: false};
    const me = this;

    window.addEventListener("unhandledrejection", function (error) {
      // @ts-ignore
      if (ErrorBoundary.disabled) return;
      console.error("unhandledrejection", error);
      if (typeof error === 'object' && error !== null && error.reason === "ivaoUnauthorized") {
        ErrorBoundary.disabled = true
        return;
      }

      me.setState({error: true})
    });

  }

  componentDidCatch(_error, _errorInfo) {
    console.error("componentDidCatch", {_error}, _errorInfo);
    if (_error.message === "error loading dynamically imported module") // ViteJS
      window.location.reload();
    // @ts-ignore
    if (ErrorBoundary.disabled) return;
    this.setState({
      error: true
    })
  }

  handleClean() {
    cleanLocalStorage();
    window.location.reload();
  }

  render() {
    // @ts-ignore
    if (this.state.error) {
      return (
        <div className={'container error_boundary'} style={{textAlign: 'center', position: 'relative'}}>
          <div className="error_boundary_container align-self-center">
            <img src="https://static.ivao.aero/img/surprised-plane.png" height={500} alt={"SurprisedPlane"}/>
            <h3 style={{margin: 10}}>Sorry, Something went wrong</h3>
            <h5>Try reloading the page. We are working hard to fix the issue.</h5>
            <p>If after reloading several times the problem still occurs, try pressing cleans.</p>
            <div style={{margin: 10}}>
              <button className={"btn btn-primary error_boundary_button"}
                      onClick={() => window.location.reload()}>Reload
              </button>
              <button className={"btn btn-primary error_boundary_button"} onClick={() => this.handleClean()}>Clean
              </button>
            </div>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
