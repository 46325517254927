import React from "react";
import "./slider.scss";

type SliderProps = {
  title: string;
  subtitle?: string;
  backgroundImage?: string;
};
export const Slider: React.FC<SliderProps> = ({ title, subtitle, backgroundImage }) => {
  return (
    <div className="slider_container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="row"><h1 className="font-weight-bold text-white ml-md-4">{title}</h1></div>
      {subtitle && <div className="row" style={{marginTop: -15}}><h5 className="font-weight-bold text-white ml-md-4">{subtitle}</h5></div>}
    </div>
  );
};
