import { get as getSetting } from "./settings";
import moment from "moment";

export function checkDarkMode() {
  if (getSetting("dark_mode")) {
    document.body.setAttribute("data-theme", "dark");
  } else {
    document.body.removeAttribute("data-theme");
  }
}

export function renderFrequency(frequency: number): string {
  const length = 3;
  const nums = frequency.toString().split(".");

  let right;
  let left = "0".repeat(length) + nums[0];
  left = left.substring(left.length, left.length - length);

  if (nums.length > 1) {
    right = nums[1] + "0".repeat(length);
    right = right.substring(0, length);
  } else {
    right = "000";
  }

  return left + "." + right;
}

export function durationTime(time: number): string {
  const hours = Math.trunc(time / 3600);
  const minutes = Math.trunc(time / 60 - hours * 60);

  return `${zeroLeftAdder(hours.toString(), 2)}:${zeroLeftAdder(
    minutes.toString(),
    2
  )}`;
}

export function dateFormat(date: string): string {
  return moment.utc(date).format("YYYY-MM-DD | HH:mm:ss");
}

export function zeroLeftAdder(str: string, length: number): string {
  if (length - str.length > 0) str = "0".repeat(length - str.length) + str;
  return str;
}

export function fullName(user: any, showVid: boolean = false): string {
  if (!user) return "N/A";
  if (!user.firstName || !user.lastName) return user.id;
  return `${user.firstName} ${user.lastName}${showVid ? ` (${user.id})` : ""}`;
}
