import React from "react";
import "./settings-page.scss";
import {SettingsPropsType, SettingsSection} from "./SettingsSection";

type SettingsPageType = {
  items: SettingsPropsType[]
}


export const SettingsPage: React.FC<SettingsPageType> = ({items}) => {
  const leftColumn = [];
  const rightColumn = [];

  items.forEach((item, index) => {
    const elem = <div className="settings_page_column">
      <SettingsSection {...item} />
    </div>;

    index % 2 === 0 && leftColumn.push(elem);
    index % 2 !== 0 && rightColumn.push(elem);
  })

  return <div className="row">
    <div className="col-12 col-xl-6">{leftColumn}</div>
    <div className="col-12 col-xl-6">{rightColumn}</div>
  </div>
}
