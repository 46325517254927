import React from "react";
import "./footer.scss";


export const Footer = () => {
  return <footer className="footer">
    <div className={"container"} style={{paddingBottom: 100}}>
      <div className={"row align-items-center justify-content-center"}>
        <div className={"col-6"}>
          <div className="footer_logo"/>
        </div>
        <div className={"col-6 text-right footer_content"}>
          <a>{new Date().getUTCFullYear()} © International Virtual Aviation Organisation.</a><br/>
          <a>All Rights Reserved. <a href="https://wiki.ivao.aero/en/home/ivao/privacypolicy" target="_blank">Privacy Policy</a> |
            <a> <a href="https://wiki.ivao.aero/en/home/ivao/terms-of-use" target="_blank">Terms of Use</a></a></a>
        </div>
      </div>
    </div>
  </footer>;
}
