import React, {useState} from "react";
import {ChromePicker} from 'react-color'
import {InputField} from "./InputField";

type ColorPickerProps = {
  onChange: any;
  value: string;
};

export const ColorPicker: React.FC<ColorPickerProps> = ({onChange, value}) => {
  const [show, setShow] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<string>(value);

  const onClose = () => {
    onChange(newValue);
    setShow(false);
  }

  const inputChange = (value) => {
    setNewValue(value);
    onChange(value);
  }

  return (<>
      <div onClick={() => setShow(true)}>
        <InputField onChange={a => inputChange(a.target.value)} value={newValue} style={{color: newValue}}
                    className={"text-right"}/>
      </div>
      {show && <div style={{position: 'relative'}}>
        <div style={{position: 'absolute', zIndex: 2}}>
          <div style={{position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px'}} onClick={onClose}/>
          <div className={"form_color_picker"}>
            <ChromePicker color={newValue || "#fff"} onChange={a => setNewValue(a.hex)}/>
          </div>
        </div>
      </div>}
    </>
  );
}
;
