import React, { useEffect, useState } from "react";
import { setCookie, getCookieValue } from "cookies-utils";
import TagManager from "react-gtm-module";
import "./cookies-policy.scss";

type CookiesPolicyType = {
  gtm: string;
  forced?: boolean;
};

export const CookiesPolicy: React.FC<CookiesPolicyType> = ({ gtm, forced }) => {
  const cookie_key = "_ivao_cookies";
  const [status, setStatus] = useState<string>(getCookieValue(cookie_key));
  const [open, setOpen] = useState<boolean>(
    status === undefined || status === null
  );

  useEffect(() => {
    status &&
      setCookie({
        name: cookie_key,
        value: status,
        domain: ".ivao.aero",
        path: "/",
        maxAge: 7 * 24 * 3600,
        secure: true,
        sameSite: "strict",
      });

    switch (status) {
      case "active":
        TagManager.initialize({ gtmId: gtm });
        setOpen(false);
        break;
      case "disabled":
        setOpen(false);
        break;
      default:
        return;
    }
  }, [status]);

  if (!open) return <></>;

  return (
    <div className="cookies">
      <div className="cookies_container">
        {forced && <div className="cookies_container_non_click" />}
        <div className="cookies_container_click">
          <div className="cookies_container_content">
            <p>
              We use cookies to get statistics on how this website is used and
              to improve user experience.
            </p>
            <div className="row">
              <div className="col-3" style={{ marginRight: 15 }}>
                <button
                  className="btn btn-primary cookies_container_content_button"
                  onClick={() => setStatus("active")}
                >
                  Continue
                </button>
              </div>
              <div className="col-3">
                <button
                  className="btn btn-secondary cookies_container_content_button"
                  onClick={() => setStatus("disabled")}
                >
                  Disabled
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
