import React from "react";
import ToastNotification from "./ToastNotification";

export type Notification = {
  level: "ERROR" | "SUCCESS" | "INFO";
  title?: string;
  message: string;
};

type DispatchNotification = (notification: Notification) => void;

const NotificationsContext = React.createContext<{
  dispatchNotification: DispatchNotification;
}>({
  dispatchNotification: () => {
    throw new Error("Using context with default provider");
  },
});

const NotificationsProvider: React.FC = ({ children }) => {
  const [show, setShow] = React.useState(false);
  const [notification, setNotification] = React.useState({} as Notification);

  const dispatchNotification = (notification: Notification) => {
    setNotification(notification);
    setShow(true);
  };

  return (
    <NotificationsContext.Provider value={{ dispatchNotification }}>
      <ToastNotification
        onClose={() => setShow(false)}
        show={show}
        notification={notification}
      />
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsProvider };
