import React, {SyntheticEvent} from "react";
import "./form.scss";

type FieldClearButtonProps = {
  id: string;
  handleFieldClear(id: string, event: SyntheticEvent): void;
  disabled?: boolean;
};

export const FieldClearButton: React.FC<FieldClearButtonProps> = ({id, handleFieldClear, disabled = false}) => {
  return (
    <div className="input-group-append">
      <button onClick={(e) => handleFieldClear(id, e)} className="btn font-weight-bold form_button"
              type="button" aria-label="Clear" disabled={disabled}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};
