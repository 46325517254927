let localStoragePrefix;
const calculateKey = (key: string) => `${localStoragePrefix}_${key}`;

export const init = (prefix?: string) => (localStoragePrefix = prefix);
export const get = (key: string, defaultValue: any = null) => {
  const value = localStorage.getItem(calculateKey(key));
  return value !== undefined ? value : defaultValue;
};

export const set = (key: string, value: any) => {
  localStorage.setItem(calculateKey(key), value);
};

export const remove = (key: string) =>
  localStorage.removeItem(calculateKey(key));
export const clean = () => localStorage.clear();

export const setWithExpiry = (key: string, value: any, ttl: number) => {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl * 1000,
  };

  set(key, JSON.stringify(item));
};

export const getWithExpiry = (key: string, defaultValue: any = null) => {
  const itemStr = get(key);

  if (itemStr === undefined) return defaultValue;

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    remove(key);
    return defaultValue;
  }

  return item.value;
};
