import React from "react";
import "./backgroud-card.scss";

type BackgroundCardType = {
  backgroundImage: any;
  LinkComponent: any;
  link: string;
  text: string
};

export const BackgroundCard: React.FC<BackgroundCardType> = ({backgroundImage, LinkComponent, link, text}) => {
  return (
    <div className="background_card_button">
      <div className="background_card_logo">{backgroundImage}</div>
      <LinkComponent to={link} className="d-block w-100 h-100 pt-5 text-center text-white text-uppercase font-weight-bold">
        {text}
      </LinkComponent>
    </div>
  );
};
