import React from "react";
import "./listtable.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import {PulseLoader} from "../loading/PulseLoader";

type Column = {
  name: string,
  selector: string,
  headerStyle?: string
  rowStyle?: string
  format?: (arg0: any) => string | Object
}

type ListSectionProps = {
  columns: Column[],
  items: object[];
  next?: () => void,
  hasMore?: boolean,
  onClick?: (arg0: any) => void,
  enableClick?: boolean
};

export const ListTable: React.FC<ListSectionProps> = ({columns, items, next, hasMore, onClick, enableClick}) => {
  const loader = <div className={'container'}>
    <div className="list_table_loader">
      <div style={{padding: 15}}><PulseLoader/></div>
    </div>
  </div>;

  return (
    <div className={`row card pt-0 list_table_card`}>
      <InfiniteScroll dataLength={items.length} hasMore={hasMore || false} next={next || (() => null)}
                      loader={loader} className="list_table_scroll">
        <table className={`table table-hover list_table_table ${items && !hasMore ? "list_table_table_items" : ""}`}>
          <thead className={`text-white text-center`}>
          <tr>
            {columns.map((column) => {
              return <th scope="col" className={column.headerStyle || ""}>{column.name}</th>
            })}
          </tr>
          </thead>
          <tbody className={`bg-white text-center`}>
          {items.map((item) => {
            return <tr style={{...(enableClick && {cursor: 'pointer'})}}
                       onClick={onClick && (() => onClick(item))}>{columns.map((column) => {
              // @ts-ignore
              return <td className={column.rowStyle}>{column.format ? column.format(item) : item[column.selector]}</td>;
            })}</tr>
          })}
          {items.length === 0 && !hasMore && <tr>
            <th colSpan={columns.length}>There is no data</th>
          </tr>}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};
