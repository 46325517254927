import React from "react";
import "./settings-section.scss";
import Select from "react-select";
import Switch from "react-switch";
import {ColorPicker} from "../form/ColorPicker";


export type SettingsPropsType = {
  title: string;
  items: SettingType[];
  callback?: (key: string, environment: string, value: any) => void
}

type SettingType = {
  name: string,
  key: string,
  type: string,
  environment?: string
  value: any
  options?: any
}

const selectCustomStyles = {
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: "black",
  }),
  option: (provided: any, state: any) => {
    let background = undefined;
    if (state.isSelected) background = "#3C55AC";
    else if (state.isFocused) background = "#3C55AC";

    return {
      ...provided,
      color: "black",
      background: background,
    };
  },
};

export type SettingsBuilderType = {
  Switch?: React.Component
  Select?: React.Component
  ColorPicker?: React.Component
}

export const SettingsSection: React.FC<SettingsPropsType> = ({title, items, callback}) => {
  const switchOnChange = (key: string, environment: string) => {
    return (value: boolean) => {
      callback(key, environment, value);
    };
  };

  const selectOnChange = (key: string, environment: string) => {
    return ({value}) => {
      callback(key, environment, value);
    };
  };

  const colorOnChange = (key: string, environment: string) => {
    return (value: any) => {
      callback(key, environment, value);
    };
  };

  const SettingComponent = ({type, settingKey, environment, value, options}) => {
    switch (type) {
      case 'boolean':
        // @ts-ignore
        return <Switch onChange={switchOnChange(settingKey, environment)} checked={value} offColor={"#E93434"}
                       onColor={"#2EC662"} uncheckedIcon={false} checkedIcon={false}/>;
      case 'select':
        return <Select
          value={value}
          isSearchable={false}
          className="settings_select"
          options={options}
          onChange={selectOnChange(settingKey, environment)}
          styles={selectCustomStyles}
        />;
      case 'color':
        return <ColorPicker value={value} onChange={colorOnChange(settingKey, environment)}/>
      default:
        return <>@TODO</>;
    }
  }

  const renderSetting = ({name, key, type, environment = 'default', value, options}) => {
    return <div className="row settings_container_row">
      <div className={"col-7 settings_container_col text-left"}><p>{name}</p></div>
      <div className={"col-5 settings_container_col text-right"}>
        <SettingComponent type={type} settingKey={key} environment={environment} value={value} options={options}/>
      </div>
    </div>
  }

  return <div className="settings">
    <div><h1 className="settings_title">{title}</h1></div>
    <div className="settings_container">
      {items.map(renderSetting)}
    </div>
  </div>
}
