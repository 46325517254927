import React from "react";
import "./lateral-card.scss";

type CardProps = {
  lateralText?: string;
  text: string;
  lateralBackground: string;
  mainBackground: string;
  textColor?: string;
  lateralColor?: string
};

export const LateralCard: React.FC<CardProps> = (
  {
    lateralText,
    text,
    lateralBackground,
    mainBackground,
    textColor = "white",
    lateralColor = "white",
  }) => {
  return (
    <div className={`text-white lateral_card_card`} style={{backgroundColor: mainBackground}}>
      <div className={`font-weight-bold text-uppercase lateral_card_card_lateral text-${lateralColor}`}
           style={{backgroundColor: lateralBackground}}>
        <a>{lateralText}</a>
      </div>
      <div
        className={`h-100 text-center font-weight-bold text-uppercase d-flex align-items-center justify-content-center pl-2 text-${textColor} lateral_card_main_div`}
      >
        <a>{text}</a>
      </div>
    </div>
  );
};
