import React from "react";
import "./trey-header.scss";

type TreyHeaderType = {
  leftComponent: any,
  centerComponent: any,
  rightComponent: any
}

export const TreyHeader: React.FC<TreyHeaderType> = (props) => {
  return (
    <div className="row justify-content-md-between justify-content-center">
      <div className="col-12 col-md-4 trey_header trey_header_left">
        {props.leftComponent}
      </div>
      <div className="w-100 pt-3 pt-md-0 d-md-none"/>
      <div className="col-12 col-md-4 trey_header trey_header_center">
        {props.centerComponent}
      </div>
      <div className="w-100 pt-3 py-md-0 d-md-none"/>
      <div className="col-12 col-md-4 trey_header trey_header_right">
        {props.rightComponent}
      </div>
    </div>
  );
};
